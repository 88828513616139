export let langArr = {};
langArr.en = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.sw = {
'loading': 'Inapakia',
'hotkeys': 'Vifunguo vya haraka',
'hotkeys_enabled': 'Vifunguo vya haraka vimewezeshwa',
'enable_hotkeys_checkbox': 'Washa vifunguo vya haraka kwa kuweka alama.',
'make_bet': 'Weka dau',
'double_bet': 'Ongeza dau mara mbili',
'halve_bet': 'Punguza dau kwa nusu',
'min_bet': 'Kiasi cha chini cha dau',
'cashout_key': 'Toa pesa',
'walk_next_line': 'Tembea kwa mstari unaofuata',
'max': 'Kikomo',
'easy': 'Rahisi',
'medium': 'Kati',
'hard': 'Ngumu',
'daredevil': 'Mjasiri',
'max_profit': 'Faida ya juu kabisa',
'manual': 'Mwongozo',
'auto': 'Otomatiki',
'bet_amount': 'Kiasi cha dau',
'difficulty': 'Ugumu',
'payout_on_win': 'Malipo kwa Ushindi',
'number_of_bets': 'Idadi ya Madau',
'on_win': 'Wakati wa Ushindi',
'on_loss': 'Wakati wa Kupoteza',
'stop_on_profit': 'Acha kwenye Faida',
'stop_on_loss': 'Acha kwenye Hasara',
'reset': 'Weka upya',
'increase_by': 'Ongeza kwa',
'cashout': 'TOA FEDHA',
'start_game': 'BETI',
'start_auto_game': 'ANZA',
'difficulity_info': 'Maelezo ya Ugumu',
'chance_collision': 'Nafasi ya kugongana kwa kila mchezo',
'evry_25_lane': 'kwa kila njia 25',
'insufficiend_funds': 'Fedha hazitoshi',
'cashout_inactivity': 'Malipo ya kiotomatiki kwa kutofanya kazi',
'time_running_out': 'Muda unakaribia kuisha! Cheza sasa au tiketi yako itaondolewa moja kwa moja baada ya sekunde 30',
'there_was_error': 'KUNA HITILAFU, JARIBU TENA AU PAKIA UPYA',
'detailed_game_rules': 'Sheria za Mchezo kwa Kina',
'general_rules': 'SHERIA ZA JUMLA',
'general_rules_p1': '• Kizidishi huanza kwa 1 na kupanda hadi ',
'general_rules_p2': '• Ushindi huhesabiwa kwa kuzidisha dau na kizidishi kinachoonyeshwa kwenye kila hatua.',
'general_rules_p3': '• Ikiwa kiwango cha juu cha malipo kitafikiwa, mchezo utajitoa pesa kiotomatiki kwa kiwango cha juu cha malipo.',
'progressive_jackpot': 'JACKPOT INAYOENDELEA',
'progressive_jackpot_ss': 'Njia ya jackpot na uwezekano wa kushinda',
'progressive_jackpot_p1': '% ya kila dau huwekwa kwenye mfuko wa jackpot inayoendelea.',
'progressive_jackpot_p2': 'Jackpot hutolewa kwa kutumia mbinu ya "Mystery" au "must-hit-by". Thamani ya kila jackpot ya "must-hit-by" huamuliwa mara tu baada ya jackpot iliyotangulia kushindwa kwa kutumia jenereta ya namba za bahati nasibu na kuhifadhiwa kama thamani iliyofichwa kwenye hifadhidata ya mbali. Thamani hiyo imefichwa na haijulikani.',
'progressive_jackpot_p3': 'Mara tu thamani ya "must-hit-by" inapofikiwa, mchezaji wa kwanza kuweka dau baada ya hapo atakuwa na nafasi ya kushinda jackpot. Alama ya jackpot itaonekana kwenye njia nasibu, na jackpot itashindwa ikiwa mchezaji atafikia njia yenye alama hiyo. Ikiwa mchezaji hatafikia njia yenye alama ya jackpot kwa sababu ya kujitoa au kuanguka, mchezaji mwingine nasibu atakayeweka dau ataona alama ya jackpot na kupata nafasi ya kushinda. Mchakato huu utaendelea hadi jackpot ishindwe. Ni tiketi/mchezaji mmoja tu anaweza kushinda jackpot.',
'progressive_jackpot_p4': 'KUMBUKA: Raundi ya bonasi haichangii mfuko wa jackpot. Wakati wa raundi ya bonasi, jackpot haiwezi kushindwa.',
'showdown': 'MCHUANO',
'showdown_ss': 'Mraba mwekundu, mahali pa mgongano fulani',
'showdown_p1': 'Baada ya mchezaji kujitoa, mraba mwekundu utaonyeshwa mahali ambapo mgongano ungetokea ikiwa mchezaji angeendelea kuruka njia.',
'return_to_player': 'KUREJESHA KWA MCHEZAJI',
'return_to_player_p1': 'Rejesho la kinadharia kwa mchezaji (RTP) ni ',
'return_to_player_p2': 'Asilimia za RTP hufuatiliwa na kuthibitishwa kila mara na taasisi huru.',
'disconnection_policy': 'SERA YA KUKATIKA MTANDAO',
'disconnection_policy_p1': 'Ikiwa muunganisho utakatika baada ya dau kuwekwa, dau halitafutwa mara moja. Ikiwa kutokuwepo kwa mchezaji kutafikia ',
'disconnection_policy_p2': 'dau litajitowa kiotomatiki na pesa zitawekwa kwenye akaunti ya mchezaji.',
'network_latency': 'UCHELEWESHWAJI WA MTANDAO',
'network_latency_p1': 'Ucheleweshaji wa mtandao ni sehemu isiyoepukika ya muunganisho wa Intaneti. Tunachukulia dau na utoaji wa pesa kuwa halali tu wakati yanapofika kwenye seva zetu, bila kujali muda wa ucheleweshaji wa mtandao.',
'sound': 'SAUTI',
'sound_p1': 'Mchezo umebuniwa kwa kuzingatia muziki wa mandhari nzuri na athari za sauti. Ikiwa unataka kulemaza muziki na/au athari za sauti, unaweza kufanya hivyo kupitia menyu ya mchezo.',
'error_handling': 'USHUGHULIKIAJI WA MAKOSA',
'error_handling_p1': 'Endapo kutatokea hitilafu, timu yetu ya msaada itajaribu kutatua tatizo haraka iwezekanavyo na kuendelea na mchezo wa kawaida. Iwapo kuna tatizo na mchezo wenyewe, dau zote zitafutwa na kiasi cha dau kitarudishwa kwa wachezaji.',
'max_payout': 'Malipo ya juu zaidi ni ',
'max_bet_is': 'Dau la juu zaidi ni ',
'min_bet_is': 'Dau la chini zaidi ni ',
'per_bet': 'kwa kila dau.',
'malfunction_voids': 'Hitilafu inabatilisha malipo yote na michezo yote.',
'attention_jackpot': 'Tahadhari, Jackpot kwenye njia ',
'lane': 'njia',
'congratulations': 'hongera',
'you_won': 'umeshinda',
'balance': 'salio',
'time': 'muda',
'jackpot': 'jackpot',
'escaped': 'UMEJITOA',
'max_win': 'USHINDI WA JUU ZAIDI',
'check_internet': 'Tafadhali angalia muunganisho wako wa intaneti',
'websocket_disconected': 'Muunganisho wa WebSocket umekatika',
'websocket_connection_error': 'Hitilafu ya muunganisho wa WebSocket!',
'session_expired': 'Kipindi kimeisha, tafadhali pakia upya',
'remaining_bets': 'Dau Zilizobaki',
'stop_autoplay': 'SIMAMISHA',
'autoplay_and_autocashout': 'MCHEZO KIOTOMATI NA MALIPO KIOTOMATI',
'autoplay_and_autocashout_ss': 'Chaguo kati ya mwongozo na mchezo wa moja kwa moja',
'autoplay_and_autocashout_p1': 'Mchezaji anaweza kuwezesha chaguo la mchezo wa moja kwa moja ambapo dau huwekwa kiotomatiki kwa kila raundi mpya kwa kutumia kiasi cha dau kutoka dau la awali.',
'autoplay_and_autocashout_p2': 'Kizidishi cha utoaji wa pesa kiotomatiki kinaweza kuchaguliwa kutoka kwenye skrini ya mchezo, na kikishawekwa, mchezo utajitoa pesa kiotomatiki ikiwa mchezaji hatapoteza kabla ya kufikia kizidishi kilichochaguliwa.',
'autoplay_and_autocashout_p3': 'Idadi ya juu kabisa ya raundi za mchezo wa moja kwa moja ni ',
'autoplay_button_label': 'Kuanzisha, chagua njia.',
'min_bet_amount_is': 'Kiasi cha chini cha dau ni',
'bonus_rounds': 'RAUNDI ZA BONASI',
'bonus_rounds_ss': 'Raundi za Bonasi zenye muda wa uhalali',
'bonus_rounds_p1': 'Mchezaji anaweza kupewa raundi ya bonasi (yaani dau la bure) kulingana na ofa ya kasino. Picha ibukizi itaonekana kwenye skrini ikimjulisha mchezaji kuhusu raundi za bonasi alizopewa, idadi ya dau, kiasi kwa kila dau, na kwa hiari, muda wa uhalali.',
'bonus_rounds_p2': 'Mara baada ya kutunukiwa, mchezaji anaweza kuchagua wakati wa kuanza raundi ya bonasi. Mara tu raundi ya bonasi inapowashwa, dau la kiotomatiki lenye dau maalum linawekwa, na ni juu ya mchezaji kujitoa pesa wakati wowote.',
'bonus_rounds_p3': 'Raundi za bonasi zinaweza kuwa na kikomo cha muda, baada ya hapo zinakuwa batili na haziwezi kutumika. Muda wa uhalali, ikiwa upo, unaonyeshwa chini ya raundi ya bonasi.',
'bonus_rounds_p4': 'Kutokuwepo kwa mchezaji kunaweza kusababisha kufutwa kwa raundi ya bonasi inayofanya kazi. Katika hali hii, idadi ya raundi za bonasi inapungua, na salio la mchezaji linabaki vile vile.',
'valid_until': 'halali hadi',
'bonus': 'bonasi',
'rounds': 'mizunguko',
'win_eur': 'Shinda EUR',
'complete_your_bet': 'Tafadhali kamilisha dau lako kwanza',
'complete_auto_play': 'Tafadhali kamilisha mchezo kiotomatiki kwanza',
'yes': 'ndio',
'no': 'hapana',
'play_bonus_rounds': 'Cheza mizunguko ya bonasi?',
'bet_history': 'Historia ya Dau',
'jackpot_history': 'Historia ya Jackpot',
'ticket_id': 'ID ya Tiketi',
'created': 'ilianzishwa',
'currency': 'sarafu',
'bet': 'dau',
'bet_eur': 'dau EUR',
'lanes': 'njia',
'cars': 'magari',
'multiplier': 'kizidishi',
'win': 'ushindi',
'no_betting_history': 'Hakuna historia ya dau. Weka dau, na itaonekana hapa.',
'no_jackpot_history': 'Hakuna historia ya jackpot inayopatikana.',
'date': 'tarehe',
'user': 'mtumiaji',
'bet_already_active': 'Dau tayari linaendelea',
'please_play_the_game_in_portait_mode': 'Tafadhali cheza mchezo katika mtazamo wima.',
'bonus_history': 'Historia ya bonasi',
'bonus_type': 'Aina ya bonasi',
'no_bonus_history': 'Hakuna historia ya bonasi inayopatikana.',
'game_menu': 'Menyu ya Mchezo',
'game_menu_ss': 'Kutoka kushoto: Nyumbani, Historia ya Dau, Kanuni za Mchezo, Vifupisho vya Kibodi, Mipangilio ya Sauti FX, Muziki',
'game_menu_p1': 'Kupitia menyu ya mchezo, wachezaji wanaweza kudhibiti chaguo za sauti, vifupisho vya kibodi, na kufungua historia ya mchezo pamoja na kanuni za mchezo.',
'game_menu_p2': 'Historia ya mchezo itaonyesha dirisha lenye dau za awali na historia ya bonasi.',
};
langArr.zu = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.xh = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.af = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.so = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.tn = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.ar = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.bg = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.de = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.el = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.es = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.fr = {
'loading': 'Chargement',
'hotkeys': 'Raccourcis clavier',
'hotkeys_enabled': 'Raccourcis clavier activés',
'enable_hotkeys_checkbox': 'Activez les raccourcis clavier ci-dessous en cochant cette case.',
'make_bet': 'Faire un pari',
'double_bet': 'Doubler un montant de pari',
'halve_bet': 'Diviser un montant de pari par deux',
'min_bet': 'Montant minimum du pari',
'cashout_key': 'Encaisser',
'walk_next_line': 'Avancer à la ligne suivante',
'max': 'Max',
'easy': 'Facile',
'medium': 'Moyen',
'hard': 'Difficile',
'daredevil': 'Casse-cou',
'max_profit': 'Profit maximal',
'manual': 'Manuel',
'auto': 'Auto',
'bet_amount': 'Montant du pari',
'difficulty': 'Difficulté',
'payout_on_win': 'Gain en cas de victoire',
'number_of_bets': 'Nombre de paris',
'on_win': 'En cas de victoire',
'on_loss': 'En cas de perte',
'stop_on_profit': 'Arrêter en cas de profit',
'stop_on_loss': 'Arrêter en cas de perte',
'reset': 'Réinitialiser',
'increase_by': 'Augmenter de',
'cashout': 'ENCACHER',
'start_game': 'PARIER',
'start_auto_game': 'DÉMARRER',
'difficulity_info': 'Infos sur la difficulté',
'chance_collision': 'Chance de collision par jeu',
'evry_25_lane': 'Tous les 25 parcours',
'insufficiend_funds': 'Fonds insuffisants',
'cashout_inactivity': 'Encaissement en cas d\'inactivité',
'time_running_out': 'Le temps est presque écoulé ! Jouez maintenant ou votre ticket sera retiré automatiquement dans 30 secondes',
'there_was_error': 'IL Y A EU UNE ERREUR, VEUILLEZ ESSAYER À NOUVEAU OU RECHARGER',
'detailed_game_rules': 'Règles du jeu détaillées',
'general_rules': 'RÈGLES GÉNÉRALES',
'general_rules_p1': '• Le multiplicateur commence à 1 et monte jusqu\'à',
'general_rules_p2': '• Les gains sont calculés en multipliant le montant du pari par le multiplicateur affiché à chaque étape.',
'general_rules_p3': '• Si le paiement maximum est atteint, le jeu encaissera automatiquement au montant du paiement maximum.',
'progressive_jackpot': 'JACKPOT PROGRESSIF',
'progressive_jackpot_ss': 'Le parcours du jackpot et le gain possible',
'progressive_jackpot_p1': '% de chaque mise est dédié à un fonds de jackpot progressif.',
'progressive_jackpot_p2': 'Le jackpot est attribué en utilisant une mécanique "Mystery" ou "must-hit-by". La valeur de chaque jackpot "must-hit-by" est déterminée immédiatement après qu\'un jackpot précédent a été gagné par un générateur de nombres aléatoires et stockée comme une valeur cryptée dans la base de données distante. La valeur est cryptée et donc cachée et n\'est pas connue.',
'progressive_jackpot_p3': 'Une fois que la valeur "must-hit-by" est atteinte, le premier joueur à parier après cette valeur aura la chance de gagner le jackpot. Un symbole de jackpot apparaîtra sur un parcours aléatoire, et le jackpot sera gagné si le joueur atteint le parcours avec le symbole. Si le joueur ne parvient pas à atteindre le parcours avec le symbole du jackpot en encaissant ou en s\'écrasant, le prochain joueur aléatoire à parier verra apparaître le symbole du jackpot et aura la chance de gagner. Ce processus se répète jusqu\'à ce que le jackpot soit gagné. Un seul ticket/joueur peut gagner le jackpot.',
'progressive_jackpot_p4': 'NOTE : Le tour bonus ne contribue pas au fonds du jackpot. Pendant le tour bonus, le jackpot ne peut pas être gagné.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Carré rouge, emplacement de la collision certaine',
'showdown_p1': 'Après que le joueur ait encaissé, un carré rouge sera affiché à l\'emplacement où la collision se serait produite si le joueur avait continué à sauter les parcours.',
'return_to_player': 'RETOUR AU JOUEUR',
'return_to_player_p1': 'Le retour théorique au joueur (RTP) global est de',
'return_to_player_p2': 'Les pourcentages RTP sont continuellement surveillés et vérifiés par des tiers indépendants.',
'disconnection_policy': 'POLITIQUE DE DÉCONNEXION',
'disconnection_policy_p1': 'Si la connexion est perdue après qu\'un pari ait été placé, le pari ne sera pas annulé immédiatement. Si l\'inactivité du joueur atteint',
'disconnection_policy_p2': 'le pari sera automatiquement encaissé et les crédits seront appliqués au compte du joueur.',
'network_latency': 'LATENCE RÉSEAU',
'network_latency_p1': 'La latence réseau fait partie intégrante de la connectivité Internet. Nous considérons tous les paris et encaissements comme valides uniquement au moment où ils atteignent notre serveur, quelle que soit la durée de la latence réseau.',
'sound': 'SON',
'sound_p1': 'Le jeu est conçu avec une belle musique de fond et des effets sonores. Si vous souhaitez désactiver la musique et/ou les effets sonores, vous pouvez le faire via les icônes du menu du jeu.',
'error_handling': 'GESTION DES ERREURS',
'error_handling_p1': 'En cas d\'erreur, notre équipe d\'assistance tentera de résoudre le problème dès que possible et de reprendre le jeu normalement. En cas de problème avec le jeu lui-même, tous les paris seront annulés et le montant du pari sera retourné aux joueurs.',
'max_payout': 'Le paiement maximum est de',
'max_bet_is': 'Le pari maximum est de',
'min_bet_is': 'Le pari minimum est de',
'per_bet': 'par pari.',
'malfunction_voids': 'Le dysfonctionnement annule tous les paiements et jeux.',
'attention_jackpot': 'Attention, jackpot sur le parcours',
'lane': 'parcours',
'congratulations': 'félicitations',
'you_won': 'vous avez gagné',
'balance': 'solde',
'time': 'temps',
'jackpot': 'jackpot',
'escaped': 'ÉCHAPPÉ',
'max_win': 'GAGNE MAXIMAL',
'check_internet': 'Veuillez vérifier votre connexion Internet',
'websocket_disconected': 'Websocket déconnecté',
'websocket_connection_error': 'Erreur de connexion Websocket !',
'session_expired': 'Session expirée, veuillez recharger',
'remaining_bets': 'Paris restants',
'stop_autoplay': 'ARRÊTER',
'autoplay_and_autocashout': 'AUTOPLAY ET AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Sélection entre manuel et autoplay',
'autoplay_and_autocashout_p1': 'Un joueur peut activer l\'option autoplay où un pari est automatiquement placé pour chaque nouveau tour en utilisant le montant du pari du tour précédent.',
'autoplay_and_autocashout_p2': 'Un multiplicateur d\'encaissement automatique peut être sélectionné depuis l\'écran du jeu, et une fois défini, le jeu encaissera automatiquement si le joueur n\'est pas touché avant d\'atteindre le multiplicateur sélectionné.',
'autoplay_and_autocashout_p3': 'Le nombre maximum de tours autoplay est de',
'autoplay_button_label': 'Pour commencer, choisissez un parcours.',
'min_bet_amount_is': 'Le montant minimum du pari est',
'bonus_rounds': 'Tours bonus',
'bonus_rounds_ss': 'Les tours bonus avec période de validité',
'bonus_rounds_p1': 'Un joueur peut recevoir un tour bonus (c\'est-à-dire un pari gratuit) en fonction d\'une promotion du casino. Un graphique popup apparaîtra à l\'écran pour informer le joueur des tours bonus attribués, du nombre de paris, du montant par pari et éventuellement de la période de validité.',
'bonus_rounds_p2': 'Une fois attribués, le joueur peut choisir quand commencer le tour bonus. Une fois le tour bonus commencé, un pari automatique avec la mise spécifiée est placé, et il appartient au joueur d\'encaisser à tout moment.',
'bonus_rounds_p3': 'Les tours bonus peuvent avoir une limite de temps, après laquelle ils deviennent caducs et inutilisables. La période de validité, si elle existe, est affichée sous le tour bonus.',
'bonus_rounds_p4': 'L\'inactivité du joueur peut entraîner l\'annulation d\'un tour bonus actif. Dans ce cas, le nombre de tours bonus est réduit et le solde du joueur reste le même.',
'valid_until': 'valide jusqu\'à',
'bonus': 'bonus',
'rounds': 'tours',
'win_eur': 'gagner EUR',
'complete_your_bet': 'Veuillez d\'abord compléter votre pari',
'complete_auto_play': 'Veuillez d\'abord compléter votre autoplay',
'yes': 'oui',
'no': 'non',
'play_bonus_rounds': 'Jouer aux tours bonus ?',
'bet_history': 'Historique des paris',
'jackpot_history': 'Historique des jackpots',
'ticket_id': 'ID du ticket',
'created': 'heure de création',
'currency': 'monnaie',
'bet': 'pari',
'bet_eur': 'pari EUR',
'lanes': 'parcours',
'cars': 'voitures',
'multiplier': 'multiplicateur',
'win': 'gain',
'no_betting_history': 'Aucun historique de paris trouvé. Faites un pari, il apparaîtra ici.',
'no_jackpot_history': 'Aucun historique de jackpot disponible.',
'date': 'date',
'user': 'utilisateur',
'bet_already_active': 'Le pari est déjà actif',
'please_play_the_game_in_portait_mode': 'Veuillez jouer en mode portrait.',
'bonus_history': 'Historique des bonus',
'bonus_type': 'Type de bonus',
'no_bonus_history': 'Aucun historique de bonus disponible.',
'game_menu': 'Menu du jeu',
'game_menu_ss': 'De gauche à droite : Accueil, Historique des paris, Règles du jeu, Raccourcis clavier, Paramètres des effets sonores, Musique',
'game_menu_p1': 'Grâce au menu du jeu, les joueurs peuvent contrôler les options de son, les raccourcis clavier, ainsi qu\'ouvrir l\'historique du jeu et les règles du jeu.',
'game_menu_p2': 'L\'historique du jeu affichera une fenêtre avec les paris précédents et l\'historique des bonus.',
};
langArr.he = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.hr = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.hu = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.it = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.mk = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.pl = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.pt = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.br = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.ro = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.ru = {
'loading': 'Загрузка',
'hotkeys': 'Горячие клавиши',
'hotkeys_enabled': 'Горячие клавиши включены',
'enable_hotkeys_checkbox': 'Включите горячие клавиши, показанные ниже, установив этот флажок.',
'make_bet': 'Сделать ставку',
'double_bet': 'Удвоить сумму ставки',
'halve_bet': 'Уменьшить ставку вдвое',
'min_bet': 'Минимальная ставка',
'cashout_key': 'Вывод',
'walk_next_line': 'Перейти на следующую линию',
'max': 'Макс',
'easy': 'Легко',
'medium': 'Средне',
'hard': 'Трудно',
'daredevil': 'Смельчак',
'max_profit': 'Максимальная прибыль',
'manual': 'Ручной',
'auto': 'Авто',
'bet_amount': 'Сумма ставки',
'difficulty': 'Сложность',
'payout_on_win': 'Выплата при выигрыше',
'number_of_bets': 'Количество ставок',
'on_win': 'При выигрыше',
'on_loss': 'При проигрыше',
'stop_on_profit': 'Остановить при прибыли',
'stop_on_loss': 'Остановить при проигрыше',
'reset': 'Сброс',
'increase_by': 'Увеличить на',
'cashout': 'ВЫВОД',
'start_game': 'СТАВКА',
'start_auto_game': 'СТАРТ',
'difficulity_info': 'Информация о сложности',
'chance_collision': 'Шанс столкновения в игре',
'evry_25_lane': 'на каждые 25 линий',
'insufficiend_funds': 'Недостаточно средств',
'cashout_inactivity': 'Авто-вывод при бездействии',
'time_running_out': 'Время на исходе! Играйте сейчас или ваш билет будет автоматически отозван через 30 секунд',
'there_was_error': 'ПРОИЗОШЛА ОШИБКА, ПОПРОБУЙТЕ ЕЩЁ РАЗ ИЛИ ПЕРЕЗАГРУЗИТЕ',
'detailed_game_rules': 'Подробные правила игры',
'general_rules': 'ОБЩИЕ ПРАВИЛА',
'general_rules_p1': '• Множитель начинается с 1 и увеличивается до ',
'general_rules_p2': '• Выигрыш рассчитывается путем умножения ставки на множитель, отображаемый на каждом этапе.',
'general_rules_p3': '• Если достигнут максимальный коэффициент выплаты, игра автоматически завершится с этим значением.',
'progressive_jackpot': 'ПРОГРЕССИВНЫЙ ДЖЕКПОТ',
'progressive_jackpot_ss': 'Полоса джекпота и возможный выигрыш',
'progressive_jackpot_p1': '% от каждой ставки направляется в фонд прогрессивного джекпота.',
'progressive_jackpot_p2': 'Джекпот разыгрывается с использованием механики "Мистерия" или "Должен выпасть до". Значение каждого "Должен выпасть до" джекпота определяется сразу после выигрыша предыдущего джекпота с помощью генератора случайных чисел и сохраняется как зашифрованное значение в удаленной базе данных. Значение зашифровано и скрыто, и никто не знает его заранее.',
'progressive_jackpot_p3': 'Когда достигнуто значение "Должен выпасть до", первый игрок, сделавший ставку после этого, получит шанс выиграть джекпот. Символ джекпота появится на случайной дорожке, и игрок выиграет джекпот, если достигнет этой дорожки. Если игрок не достигнет дорожки с символом джекпота из-за вывода средств или проигрыша, следующий случайный игрок, сделавший ставку, увидит появление символа джекпота и получит шанс на выигрыш. Этот процесс повторяется до тех пор, пока джекпот не будет выигран. Только один билет/игрок может выиграть джекпот.',
'progressive_jackpot_p4': 'ПРИМЕЧАНИЕ: Бонусный раунд не участвует в формировании джекпот-фонда. Во время бонусного раунда джекпот не может быть выигран.',
'showdown': 'РАЗВЯЗКА',
'showdown_ss': 'Красный квадрат – место неизбежного столкновения',
'showdown_p1': 'После вывода средств у игрока появится красный квадрат в том месте, где произошло бы столкновение, если бы он продолжил движение по дорожкам.',
'return_to_player': 'ВОЗВРАТ ИГРОКУ',
'return_to_player_p1': 'Общий теоретический возврат игроку (RTP) составляет ',
'return_to_player_p2': 'Процент RTP непрерывно контролируется и проверяется независимыми третьими сторонами.',
'disconnection_policy': 'ПОЛИТИКА ПРИ ОБРЫВЕ СОЕДИНЕНИЯ',
'disconnection_policy_p1': 'Если соединение теряется после размещения ставки, ставка не отменяется сразу. Если бездействие игрока достигает ',
'disconnection_policy_p2': 'ставка будет автоматически выведена, и средства будут зачислены на счет игрока.',
'network_latency': 'ЗАДЕРЖКА СЕТИ',
'network_latency_p1': 'Задержка сети – это неизбежная часть интернет-соединения. Мы считаем все ставки и выводы действительными только в момент их поступления на наш сервер, независимо от продолжительности сетевой задержки.',
'sound': 'ЗВУК',
'sound_p1': 'Игра разработана с учетом красивой фоновой музыки и звуковых эффектов. Если вы хотите отключить музыку и/или звуковые эффекты, вы можете сделать это через меню игры.',
'error_handling': 'ОБРАБОТКА ОШИБОК',
'error_handling_p1': 'В случае ошибки наша служба поддержки постарается решить проблему как можно скорее и возобновить нормальную работу игры. В случае неисправности самой игры все ставки будут отменены, а суммы ставок возвращены игрокам.',
'max_payout': 'Максимальная выплата составляет ',
'max_bet_is': 'Максимальная ставка составляет ',
'min_bet_is': 'Минимальная ставка составляет ',
'per_bet': 'за ставку.',
'malfunction_voids': 'Сбой аннулирует все выплаты и игры.',
'attention_jackpot': 'Внимание, Джекпот на дорожке ',
'lane': 'дорожка',
'congratulations': 'поздравляем',
'you_won': 'вы выиграли',
'balance': 'баланс',
'time': 'время',
'jackpot': 'джекпот',
'escaped': 'СПАСЕНИЕ',
'max_win': 'МАКСИМАЛЬНЫЙ ВЫИГРЫШ',
'check_internet': 'Пожалуйста, проверьте ваше интернет-соединение',
'websocket_disconected': 'Websocket отключен',
'websocket_connection_error': 'Ошибка соединения с Websocket!',
'session_expired': 'Сессия истекла, пожалуйста, перезагрузите страницу',
'remaining_bets': 'Оставшиеся ставки',
'stop_autoplay': 'СТОП',
'autoplay_and_autocashout': 'АВТОИГРА И АВТОВЫВОД',
'autoplay_and_autocashout_ss': 'Выбор между ручной игрой и автоигрой',
'autoplay_and_autocashout_p1': 'Игрок может активировать опцию автоигры, при которой ставка автоматически размещается в каждом новом раунде, используя сумму ставки из предыдущего раунда.',
'autoplay_and_autocashout_p2': 'Автоматический множитель вывода можно выбрать на игровом экране. Если он установлен, игра автоматически завершится, если игрок не столкнется до достижения выбранного множителя.',
'autoplay_and_autocashout_p3': 'Максимальное количество раундов автоигры составляет ',
'autoplay_button_label': 'Чтобы начать, выберите дорожку.',
'min_bet_amount_is': 'Минимальная сумма ставки составляет',
'bonus_rounds': 'бонусные раунды',
'bonus_rounds_ss': 'Бонусные раунды с периодом действия',
'bonus_rounds_p1': 'Игрок может получить бонусный раунд (например, бесплатную ставку) в рамках акции казино. На экране появится всплывающее сообщение, информирующее игрока о начисленных бонусных раундах, количестве ставок, сумме на ставку и, возможно, сроке действия.',
'bonus_rounds_p2': 'После начисления бонусного раунда игрок может выбрать, когда его использовать. Как только бонусный раунд активирован, автоматически размещается ставка с указанной суммой, и игрок сам решает, когда вывести выигрыш.',
'bonus_rounds_p3': 'Бонусные раунды могут иметь ограниченный срок действия, после которого они аннулируются и становятся недоступными. Если срок действия установлен, он отображается под бонусным раундом.',
'bonus_rounds_p4': 'Если игрок бездействует, активный бонусный раунд может быть отменен. В этом случае количество бонусных раундов уменьшается, а баланс игрока остается неизменным.',
'valid_until': 'срок до',
'bonus': 'бонус',
'rounds': 'раунды',
'win_eur': 'выигрыш в EUR',
'complete_your_bet': 'Пожалуйста, завершите свою ставку',
'complete_auto_play': 'Пожалуйста, завершите свою автоигру',
'yes': 'да',
'no': 'нет',
'play_bonus_rounds': 'Играть в бонусные раунды?',
'bet_history': 'История ставок',
'jackpot_history': 'История джекпотов',
'ticket_id': 'ID билета',
'created': 'время создания',
'currency': 'валюта',
'bet': 'ставка',
'bet_eur': 'ставка в EUR',
'lanes': 'дорожки',
'cars': 'машины',
'multiplier': 'множитель',
'win': 'выигрыш',
'no_betting_history': 'История ставок не найдена. Сделайте ставку, и она появится здесь.',
'no_jackpot_history': 'История джекпотов отсутствует.',
'date': 'дата',
'user': 'пользователь',
'bet_already_active': 'Ставка уже активна',
'please_play_the_game_in_portait_mode': 'Пожалуйста, играйте в портретном режиме.',
'bonus_history': 'История бонусов',
'bonus_type': 'Тип бонуса',
'no_bonus_history': 'История бонусов отсутствует.',
'game_menu': 'Игровое меню',
'game_menu_ss': 'Слева направо: Главная, История ставок, Правила игры, Горячие клавиши, Настройки звуковых эффектов, Музыка',
'game_menu_p1': 'Через игровое меню игроки могут управлять настройками звука, горячими клавишами, а также открывать историю игры и правила.',
'game_menu_p2': 'История игры покажет окно с предыдущими ставками и историей бонусов.',
};
langArr.sq = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.sv = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.tr = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.uk = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.sr = {
'loading': 'Učitavanje',
'hotkeys': 'Prečice',
'hotkeys_enabled': 'Prečice aktivne',
'enable_hotkeys_checkbox': 'Omogući prečice aktiviranjem ove opcije.',
'make_bet': 'Uplati tiket',
'double_bet': 'Dupliraj ulog',
'halve_bet': 'Prepolovi ulog',
'min_bet': 'Minimalni ulog',
'cashout_key': 'Isplata',
'walk_next_line': 'Pređi u sledeću traku',
'max': 'Max',
'easy': 'Lako',
'medium': 'Osrednje',
'hard': 'Teško',
'daredevil': 'Izazovno',
'max_profit': 'Max Profit',
'manual': 'Ručno',
'auto': 'Automatski',
'bet_amount': 'Iznos opklade',
'difficulty': 'Težina',
'payout_on_win': 'Isplata po dobitku',
'number_of_bets': 'Broj opklada',
'on_win': 'po dobitku',
'on_loss': 'po gubitku',
'stop_on_profit': 'Prekini na profitu',
'stop_on_loss': 'Prekini na gubitku',
'reset': 'Reset',
'increase_by': 'Povećaj za',
'cashout': 'ISPLATI TIKET',
'start_game': 'UPLATI TIKET',
'start_auto_game': 'START',
'difficulity_info': 'Težina igre',
'chance_collision': 'Šansa za sudar po igri',
'evry_25_lane': 'za svih 25 traka',
'insufficiend_funds': 'Nemate dovoljno novca',
'cashout_inactivity': 'Isplata usled neaktivnosti',
'time_running_out': 'Vreme ističe! Igraj odmah ili će tvoj tiket biti isplaćen u narednih 30 sekundi',
'there_was_error': 'Dogodila se greška! Učitaj igru ponovo.',
'detailed_game_rules': 'Detaljna Pravila Igre',
'general_rules': 'OPŠTA PRAVILA',
'general_rules_p1': '• Množilac počinje sa 1 i ide do ',
'general_rules_p2': '• Dobitak se izračunava množenjem opklade sa množiteljem u trenutku klika na dugme ISPLATI TIKET.',
'general_rules_p3': '• Ako je dostignut maksimalni dobitak opklada se automatski unovčava.',
'progressive_jackpot': 'PROGRESIVNI DŽEKPOT',
'progressive_jackpot_ss': 'Džekpot traka i mogućnost osvajanja',
'progressive_jackpot_p1': '% od svakog uloga se izdvaja u fond za progresivni džekpot.',
'progressive_jackpot_p2': 'Džekpot se dodeljuje putem mehanike "Mistery" ili "must-hit-by". Vrednost svakog "must-hit-by" džekpota se određuje odmah nakon što prethodni džekpot bude osvojen, koristeći generator slučajnih brojeva. Ta vrednost se čuva kao šifrovani broj u bazi podataka, što znači da je skrivena i nepoznata.',
'progressive_jackpot_p3': 'Kada se dostigne vrednost "must-hit-by", prvi igrač koji uloži nakon toga imaće priliku da osvoji džekpot. Simbol džekpota će se pojaviti na nasumičnoj traci, a džekpot se osvaja ako igrač stigne do trake sa džekpot simbolom. Ako igrač ne stigne do trake sa simbolom džekpota zbog isplate ili sudara, sledeći igrač koji uloži videće simbol džekpota i imati priliku da ga osvoji. Ovaj proces se ponavlja dok džekpot ne bude osvojen. Samo jedan igrač/tiket može da osvoji džekpot.',
'progressive_jackpot_p4': 'NAPOMENA: Besplatne opklade tj. bonus runde ne učestvuju u fondu za progresivni džekpot. Džekpot ne može biti osvoje tokom bonus runde.',
'showdown': 'OTKRIVANJE',
'showdown_ss': 'Crveni kvadrad, mesto garantovanog sudara',
'showdown_p1': 'Nakon izvršene isplate, crveni kvadrat će biti prikazan na poziciji gde bi se sudar dogodio da je igrač nastavio sa igrom.',
'return_to_player': 'POVRAĆAJ IGRAČU',
'return_to_player_p1': 'Ukupni teoretski povraćaj igraču (RTP) je ',
'return_to_player_p2': 'Procente RTP -a stalno prate i verifikuju nezavisne treće strane.',
'disconnection_policy': 'POLITIKA POVEZIVANJA',
'disconnection_policy_p1': 'Ako se veza prekine nakon postavljanja opklade, opklada se neće poništiti. Ako je igrač neaktivan više od ',
'disconnection_policy_p2': 's opklada će biti automatski isplaćena a krediti vraćeni na račun igrača.',
'network_latency': 'KAŠNJENJA U MREŽI',
'network_latency_p1': 'Kašnjenja prilikom prenosa podataka u mreži su sastavni deo funkcionisanja interneta. Akcije Uplate i Isplate su validne onoga trenutka kada ti zahtevi stignu na server, bez obzira na to kada je igrač inicirao akciju na svojoj strani.',
'sound': 'ZVUK',
'sound_p1': 'Igra je dizajnirana sa prelepom muzikom u pozadini i zvučnim efektima. Ako želite da ugasite muziku ili zvučne efekte korisite ikone za kontrolu zvuka iz menija igre.',
'error_handling': 'U SLUČAJU GREŠKE',
'error_handling_p1': 'U slučaju greške naš tim za podršku će pokušati rešiti problem što je pre moguće. U slučaju problema sa samom igrom, sve opklade će biti poništene, a iznos uloga vraćen igračima.',
'max_payout': 'Maksimalna isplata je ',
'max_bet_is': 'Maksimalni ulog je ',
'min_bet_is': 'Minimalni ulog je ',
'per_bet': 'po opkladi.',
'malfunction_voids': 'Neispravnost u radu poništava sve isplate i igre.',
'attention_jackpot': 'Pažnja, Džekpot na traci ',
'lane': 'traka',
'congratulations': 'bravo',
'you_won': 'osvojio si',
'balance': 'balans',
'time': 'vreme',
'jackpot': 'džekpot',
'escaped': 'POBEGAO',
'max_win': 'MAX DOBITAK',
'check_internet': 'Molimo proverite internet konekciju',
'websocket_disconected': 'Websocket diskonektovan',
'websocket_connection_error': 'Websocket greska u konekciji !',
'session_expired': 'Sesija je istekla',
'remaining_bets': 'Preostale opklade',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTO-IGRA I AUTO-ISPLATA',
'autoplay_and_autocashout_ss': 'Izbor ručne i automatske igre',
'autoplay_and_autocashout_p1': 'Igrač može aktivirati opciju auto-igra gde se opklada automatski stavlja za svaku novu rundu koristeći iznos opklade iz prethodne runde. ',
'autoplay_and_autocashout_p2': 'Odabir množioca za svaku partiju se vrši sa ekran igra. Kada je množilac podešen igra će automatski izvršiti isplatu ako igrač ne doživi sudar pre nego što dostigne izabrani množilac.',
'autoplay_and_autocashout_p3': 'Maskimalan broj rundi u automatskoj igri je ',
'autoplay_button_label': 'Za pocetak, izaberi traku.',
'min_bet_amount_is': 'Min iznos opklade je',
'bonus_rounds': 'bonus runde',
'bonus_rounds_ss': 'Prikaz bonus rundi sa rokom važenja',
'bonus_rounds_p1': 'Igrač može biti nagrađen besplatnim opkladama u vidu bonus rundi tokom promocije od strane kazina. Na ekranu će iskočiti slika koja obaveštava igrača o dodeljenim besplatnim rundama, broju rundi, iznosu po opkladi i opciono roku važenja.',
'bonus_rounds_p2': 'Nakon što se dodele bonus runde, igrač može izabrati kada počinje besplatnu rundu opklade. Nakon što započne runda besplatnih opklada, postavlja se automatska opklada sa navedenim ulogom, a na igraču je da isplati u svakom trenutku.',
'bonus_rounds_p3': 'Besplatne opklade mogu imati rok važenja nakon čega ističu i ne mogu se koristiti. Rok važenja, ako postoji, je prikazan ispod bonus rundi.',
'bonus_rounds_p4': 'Neaktivnost igrača moze prouzrokovati otkazivanje aktivne bonus runde. U tom slučaju, broj bonus rundi se smanjuje a balans igrača ostaje nepromenjen.',
'valid_until': 'važi do',
'bonus': 'bonus',
'rounds': 'runda',
'win_eur': 'dobitak EUR',
'complete_your_bet': 'Prvo završite vašu opkladu',
'complete_auto_play': 'Prvo završite vašu auto-igru',
'yes': 'da',
'no': 'ne',
'play_bonus_rounds': 'Igraj Bonus runde?',
'bet_history': 'Istorija opklada',
'jackpot_history': 'Istorija džekpota',
'ticket_id': 'Ticket ID',
'created': 'vreme kreiranja',
'currency': 'valuta',
'bet': 'ulog',
'bet_eur': 'ulog EUR',
'lanes': 'trake',
'cars': 'automobili',
'multiplier': 'množilac',
'win': 'dobitak',
'no_betting_history': 'Istorija opklada nije pronađena. Napravite opkladu i ona će se pojaviti ovde.',
'no_jackpot_history': 'Nema dostupne istorije džekpota.',
'date': 'datum',
'user': 'igrač',
'bet_already_active': 'Opklada je već aktivna',
'please_play_the_game_in_portait_mode': 'Molim vas igrajte u portret modu.',
'bonus_history': 'Istorija bonusa',
'bonus_type': 'Tip bonusa',
'no_bonus_history': 'Nema dostupne istorije bonusa.',
'game_menu': 'MENI IGRE',
'game_menu_ss': 'Sa leva na desno: povratak na početak, istorija opklada, pravila igre, prečice, zvučni efekti, muzika',
'game_menu_p1': 'Kroz meni igre, igrači mogu kontrolisati opcije zvuka, prečice na tastaturi, otvoriti istoriju opklada i pravila igre.',
'game_menu_p2': 'Istorija oplada prikazuje prethodne opklade i istoriju bonusa.',
};
langArr.rs = {
'loading': 'Učitavanje',
'hotkeys': 'Prečice',
'hotkeys_enabled': 'Prečice aktivne',
'enable_hotkeys_checkbox': 'Omogući prečice aktiviranjem ove opcije.',
'make_bet': 'Uplati tiket',
'double_bet': 'Dupliraj ulog',
'halve_bet': 'Prepolovi ulog',
'min_bet': 'Minimalni ulog',
'cashout_key': 'Isplata',
'walk_next_line': 'Pređi u sledeću traku',
'max': 'Max',
'easy': 'Lako',
'medium': 'Osrednje',
'hard': 'Teško',
'daredevil': 'Izazovno',
'max_profit': 'Max Profit',
'manual': 'Ručno',
'auto': 'Automatski',
'bet_amount': 'Iznos opklade',
'difficulty': 'Težina',
'payout_on_win': 'Isplata po dobitku',
'number_of_bets': 'Broj opklada',
'on_win': 'po dobitku',
'on_loss': 'po gubitku',
'stop_on_profit': 'Prekini na profitu',
'stop_on_loss': 'Prekini na gubitku',
'reset': 'Reset',
'increase_by': 'Povećaj za',
'cashout': 'ISPLATI TIKET',
'start_game': 'UPLATI TIKET',
'start_auto_game': 'START',
'difficulity_info': 'Težina igre',
'chance_collision': 'Šansa za sudar po igri',
'evry_25_lane': 'za svih 25 traka',
'insufficiend_funds': 'Nemate dovoljno novca',
'cashout_inactivity': 'Isplata usled neaktivnosti',
'time_running_out': 'Vreme ističe! Igraj odmah ili će tvoj tiket biti isplaćen u narednih 30 sekundi',
'there_was_error': 'Dogodila se greška! Učitaj igru ponovo.',
'detailed_game_rules': 'Detaljna Pravila Igre',
'general_rules': 'OPŠTA PRAVILA',
'general_rules_p1': '• Množilac počinje sa 1 i ide do ',
'general_rules_p2': '• Dobitak se izračunava množenjem opklade sa množiteljem u trenutku klika na dugme ISPLATI TIKET.',
'general_rules_p3': '• Ako je dostignut maksimalni dobitak opklada se automatski unovčava.',
'progressive_jackpot': 'PROGRESIVNI DŽEKPOT',
'progressive_jackpot_ss': 'Džekpot traka i mogućnost osvajanja',
'progressive_jackpot_p1': '% od svakog uloga se izdvaja u fond za progresivni džekpot.',
'progressive_jackpot_p2': 'Džekpot se dodeljuje putem mehanike "Mistery" ili "must-hit-by". Vrednost svakog "must-hit-by" džekpota se određuje odmah nakon što prethodni džekpot bude osvojen, koristeći generator slučajnih brojeva. Ta vrednost se čuva kao šifrovani broj u bazi podataka, što znači da je skrivena i nepoznata.',
'progressive_jackpot_p3': 'Kada se dostigne vrednost "must-hit-by", prvi igrač koji uloži nakon toga imaće priliku da osvoji džekpot. Simbol džekpota će se pojaviti na nasumičnoj traci, a džekpot se osvaja ako igrač stigne do trake sa džekpot simbolom. Ako igrač ne stigne do trake sa simbolom džekpota zbog isplate ili sudara, sledeći igrač koji uloži videće simbol džekpota i imati priliku da ga osvoji. Ovaj proces se ponavlja dok džekpot ne bude osvojen. Samo jedan igrač/tiket može da osvoji džekpot.',
'progressive_jackpot_p4': 'NAPOMENA: Besplatne opklade tj. bonus runde ne učestvuju u fondu za progresivni džekpot. Džekpot ne može biti osvoje tokom bonus runde.',
'showdown': 'OTKRIVANJE',
'showdown_ss': 'Crveni kvadrad, mesto garantovanog sudara',
'showdown_p1': 'Nakon izvršene isplate, crveni kvadrat će biti prikazan na poziciji gde bi se sudar dogodio da je igrač nastavio sa igrom.',
'return_to_player': 'POVRAĆAJ IGRAČU',
'return_to_player_p1': 'Ukupni teoretski povraćaj igraču (RTP) je ',
'return_to_player_p2': 'Procente RTP -a stalno prate i verifikuju nezavisne treće strane.',
'disconnection_policy': 'POLITIKA POVEZIVANJA',
'disconnection_policy_p1': 'Ako se veza prekine nakon postavljanja opklade, opklada se neće poništiti. Ako je igrač neaktivan više od ',
'disconnection_policy_p2': 's opklada će biti automatski isplaćena a krediti vraćeni na račun igrača.',
'network_latency': 'KAŠNJENJA U MREŽI',
'network_latency_p1': 'Kašnjenja prilikom prenosa podataka u mreži su sastavni deo funkcionisanja interneta. Akcije Uplate i Isplate su validne onoga trenutka kada ti zahtevi stignu na server, bez obzira na to kada je igrač inicirao akciju na svojoj strani.',
'sound': 'ZVUK',
'sound_p1': 'Igra je dizajnirana sa prelepom muzikom u pozadini i zvučnim efektima. Ako želite da ugasite muziku ili zvučne efekte korisite ikone za kontrolu zvuka iz menija igre.',
'error_handling': 'U SLUČAJU GREŠKE',
'error_handling_p1': 'U slučaju greške naš tim za podršku će pokušati rešiti problem što je pre moguće. U slučaju problema sa samom igrom, sve opklade će biti poništene, a iznos uloga vraćen igračima.',
'max_payout': 'Maksimalna isplata je ',
'max_bet_is': 'Maksimalni ulog je ',
'min_bet_is': 'Minimalni ulog je ',
'per_bet': 'po opkladi.',
'malfunction_voids': 'Neispravnost u radu poništava sve isplate i igre.',
'attention_jackpot': 'Pažnja, Džekpot na traci ',
'lane': 'traka',
'congratulations': 'bravo',
'you_won': 'osvojio si',
'balance': 'balans',
'time': 'vreme',
'jackpot': 'džekpot',
'escaped': 'POBEGAO',
'max_win': 'MAX DOBITAK',
'check_internet': 'Molimo proverite internet konekciju',
'websocket_disconected': 'Websocket diskonektovan',
'websocket_connection_error': 'Websocket greska u konekciji !',
'session_expired': 'Sesija je istekla',
'remaining_bets': 'Preostale opklade',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTO-IGRA I AUTO-ISPLATA',
'autoplay_and_autocashout_ss': 'Izbor ručne i automatske igre',
'autoplay_and_autocashout_p1': 'Igrač može aktivirati opciju auto-igra gde se opklada automatski stavlja za svaku novu rundu koristeći iznos opklade iz prethodne runde. ',
'autoplay_and_autocashout_p2': 'Odabir množioca za svaku partiju se vrši sa ekran igra. Kada je množilac podešen igra će automatski izvršiti isplatu ako igrač ne doživi sudar pre nego što dostigne izabrani množilac.',
'autoplay_and_autocashout_p3': 'Maskimalan broj rundi u automatskoj igri je ',
'autoplay_button_label': 'Za pocetak, izaberi traku.',
'min_bet_amount_is': 'Min iznos opklade je',
'bonus_rounds': 'bonus runde',
'bonus_rounds_ss': 'Prikaz bonus rundi sa rokom važenja',
'bonus_rounds_p1': 'Igrač može biti nagrađen besplatnim opkladama u vidu bonus rundi tokom promocije od strane kazina. Na ekranu će iskočiti slika koja obaveštava igrača o dodeljenim besplatnim rundama, broju rundi, iznosu po opkladi i opciono roku važenja.',
'bonus_rounds_p2': 'Nakon što se dodele bonus runde, igrač može izabrati kada počinje besplatnu rundu opklade. Nakon što započne runda besplatnih opklada, postavlja se automatska opklada sa navedenim ulogom, a na igraču je da isplati u svakom trenutku.',
'bonus_rounds_p3': 'Besplatne opklade mogu imati rok važenja nakon čega ističu i ne mogu se koristiti. Rok važenja, ako postoji, je prikazan ispod bonus rundi.',
'bonus_rounds_p4': 'Neaktivnost igrača moze prouzrokovati otkazivanje aktivne bonus runde. U tom slučaju, broj bonus rundi se smanjuje a balans igrača ostaje nepromenjen.',
'valid_until': 'važi do',
'bonus': 'bonus',
'rounds': 'runda',
'win_eur': 'dobitak EUR',
'complete_your_bet': 'Prvo završite vašu opkladu',
'complete_auto_play': 'Prvo završite vašu auto-igru',
'yes': 'da',
'no': 'ne',
'play_bonus_rounds': 'Igraj Bonus runde?',
'bet_history': 'Istorija opklada',
'jackpot_history': 'Istorija džekpota',
'ticket_id': 'Ticket ID',
'created': 'vreme kreiranja',
'currency': 'valuta',
'bet': 'ulog',
'bet_eur': 'ulog EUR',
'lanes': 'trake',
'cars': 'automobili',
'multiplier': 'množilac',
'win': 'dobitak',
'no_betting_history': 'Istorija opklada nije pronađena. Napravite opkladu i ona će se pojaviti ovde.',
'no_jackpot_history': 'Nema dostupne istorije džekpota.',
'date': 'datum',
'user': 'igrač',
'bet_already_active': 'Opklada je već aktivna',
'please_play_the_game_in_portait_mode': 'Molim vas igrajte u portret modu.',
'bonus_history': 'Istorija bonusa',
'bonus_type': 'Tip bonusa',
'no_bonus_history': 'Nema dostupne istorije bonusa.',
'game_menu': 'MENI IGRE',
'game_menu_ss': 'Sa leva na desno: povratak na početak, istorija opklada, pravila igre, prečice, zvučni efekti, muzika',
'game_menu_p1': 'Kroz meni igre, igrači mogu kontrolisati opcije zvuka, prečice na tastaturi, otvoriti istoriju opklada i pravila igre.',
'game_menu_p2': 'Istorija oplada prikazuje prethodne opklade i istoriju bonusa.',
};
langArr.zh = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.hi = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.cs = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.id = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.th = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.tl = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.xe = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.da = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
langArr.nl = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Cut the bet in half',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier displayed on every step.',
'general_rules_p3': '• If the maximum payout is reached, game will automatically cash out at the maximum payout amount.',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_ss': 'The jackpot lane and possible win',
'progressive_jackpot_p1': '% of each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "must-hit-by" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next random player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won. Only one ticket/player can win the jackpot.',
'progressive_jackpot_p4': 'NOTE: Bonus round does not contribute to a jackpot fund. During the bonus round jackpot cannot be won.',
'showdown': 'SHOWDOWN',
'showdown_ss': 'Red square, location of certain collision',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'The overall theoretical return to player (RTP) is ',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled immediately. If the inactivity from the player reaches ',
'disconnection_policy_p2': 'bet will be auto cashed out and credits will be applied to player’s account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is an unavoidable part of Internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'The game is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects, you may do so through game menu icons.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In the event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_ss': 'Selection between manual and autoplay',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An auto cashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_and_autocashout_p3': 'The maximum number of autoplay rounds is ',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_ss': 'The Bonus Rounds with validity period',
'bonus_rounds_p1': 'A player may be awarded a bonus round (i.e. free bet) based on a casino promotion. A popup graphic will appear on screen notifying the player about the awarded bonus rounds, number of bets, amount per bet and optionally validity period.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus round is started, an automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, the bonus rounds number is decreased, and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
'bonus_history': 'Bonus history',
'bonus_type': 'Bonus type',
'no_bonus_history': 'No bonus history available.',
'game_menu': 'Game menu',
'game_menu_ss': 'From left: Home, Bet history, Game rules, Keyboard shortcuts, Sound FX settings, Music',
'game_menu_p1': 'Trough game menu, players can control sound options, keyboard shortcuts, and open game history and game rules.',
'game_menu_p2': 'Game history will show window with previous bets and bonusing history.',
};
